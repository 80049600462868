.App {
  text-align: center;
  background-color: #246372;
  width: 100%;
  padding: 15px;
  margin: auto;
}

.has-tip:after{
  font-family: "Glyphicons Halflings";
  content: " \e085";
  color: #aaaaaa;
}

.center {
  padding: 10px;
  width: 100%;
  margin: auto;
  /* border: green solid; */
}

.align-center{
  text-align: center;
  vertical-align: middle;
}

.grid {
  margin: 0 auto;
  width: 1250px;
  height: 750px;
  /* border: green solid; */

  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(240px,1fr));
  grid-template-columns: repeat(5, minmax(240px,1fr));
  
  gap: 8px;
}

.bordered-grid-item {
  background-color: #d1d1d1;
  margin: auto;
  /* border: 1px red solid; */
  width: 240px;
  height: 240px;
  text-align: center;
  vertical-align: auto;
}

.bordered-grid-item-mobile {
  background-color: #d1d1d1;
  margin: auto;
  /* border: 1px rgb(54, 70, 80) dashed;  */
  width: 100%;
  height: 120px;
  text-align: center;
  vertical-align: auto;
  margin: auto;
  margin-bottom: 1px;
  margin-top: 1px;
}

.bordered-grid-item-green-mobile {
  color:rgb(0, 182, 0);
  /* border: 1px rgb(54, 70, 80) dashed;  */
  background-color: #d1d1d1;
  margin: auto;
  width: 100%;
  height: 120px;
  text-align: center;
  vertical-align: auto;
  margin: auto;
  margin-bottom: 1px;
  margin-top: 1px;
}

.bordered-grid-item-amber-mobile {
  color:rgb(214, 164, 0);
  /* border: 1px rgb(54, 70, 80) dashed;  */
  background-color: #d1d1d1;
  margin: auto;
  width: 100%;
  height: 120px;
  text-align: center;
  vertical-align: auto;
  margin: auto;
  margin-bottom: 1px;
  margin-top: 1px;
}

.bordered-grid-item-red-mobile {
  color:red;
  /* border: 1px rgb(54, 70, 80) dashed;  */
  background-color: #d1d1d1;
  margin: auto;
  width: 100%;
  height: 120px;
  text-align: center;
  vertical-align: auto;
  margin: auto;
  margin-bottom: 1px;
  margin-top: 1px;
}


.bordered-grid-item-feedback {
  background-color: #aaaaaa;
  margin: auto;
  /* border: 1px red solid; */
  width: 100%;
  height: 240px;
  text-align: center;
  vertical-align: auto;
}


.bordered-grid-item-green {
  color:rgb(0, 182, 0);
  background-color: #d1d1d1;
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: auto;
}

.bordered-grid-item-amber {
  color:rgb(214, 164, 0);
  /* text-shadow: 1px 1px black; */
  background-color: #d1d1d1;
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: auto;
}

.bordered-grid-item-red {
  color:red;
  background-color: #d1d1d1;
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: auto;
}

.top{
  text-align: left;
}

.colour-nav{
  background-color: #246372;
  width: 100%;
  margin: auto;
  align-items: center;
  align-content: center;
  text-align: center;
  /* border: green solid; */
}

.red-text{
  color:#e72d10;
  text-shadow: 2px 2px black;
}
.amber-text{
  color:rgb(214, 157, 0);
  text-shadow: 2px 2px black;
}
.green-text{
  color:rgb(0, 182, 0);
  text-shadow: 2px 2px black;
}

.disappear{
  display:none
}

.radio-container {
  display: flex;
  justify-content: center;
  text-align: center;
}

.radio-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.radio-option label {
  margin-bottom: 5px;
}

.radio-option input[type="radio"] {
  margin-bottom: 20px;
}

.responsive {
  width: 30%;
  height: auto;
}

.responsive-mobile {
  width: 100%;
  height: auto;
}

.Footer {
  color: black;
  display: flex;
  background-color: rgb(59, 59, 59);
  text-align: center;
  margin-top: auto;
  padding: 8px;
  /* border: 1px green solid; */
}

.bordered-feedback-item-mobile {
  background-color: #d1d1d1;
  margin: auto;
  border: 1px black solid;
  width: 95%;
  /* height: 180px; */
  height: auto;
  text-align: center;
  vertical-align: auto;
  margin: auto;
  margin-bottom: 3px;
  margin-top: 3px;
}
.bordered-feedback-item {
  background-color: #d1d1d1;
  margin: auto;
  border: 1px black solid;
  width: 50%;
  /* height: 180px; */
  height: auto;
  text-align: center;
  vertical-align: auto;
  margin: auto;
  margin-bottom: 3px;
  margin-top: 3px;
}